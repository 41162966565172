@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
/* You can add global styles to this file, and also import other style files */

// @import '~firebaseui/dist/firebaseui.css';
 
.ng-touched.ng-invalid {
    @apply border-red-500
}

.router-link-active {
    @apply bg-gray-900
}

.dropdown:hover .dropdown-menu {
    display: block;
  }